import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined, PartitionOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { selectContractByParam, changeSelectApplyById, updateChangeApply, changeUpdateReviseApply, updateToChangeApproval, sysgenChangeNo } from '@/api/contract';
import { selectApprovalItem, selectProfessionalByItem } from '@/api/material';
import moment from 'moment';
import Cookies from "js-cookie";
const columns = [{
  title: '收支节点',
  // className: 'column-money',
  dataIndex: 'payNode',
  fixed: 'left',
  children: [{
    title: '节点描述',
    dataIndex: 'nodeDesc',
    // key: 'companyAddress',
    slots: {
      customRender: 'nodeDesc'
    },
    width: 300,
    align: 'center'
  }, {
    title: '计划收支时间',
    dataIndex: 'planPayTime',
    // key: 'planPayTime',
    slots: {
      customRender: 'planPayTime'
    },
    width: 200,
    align: 'center'
  }]
}, {
  title: '收支条件',
  dataIndex: 'payTerms',
  slots: {
    customRender: 'payTerms'
  },
  width: 250,
  align: 'center'
}, {
  title: '收支金额(元)',
  dataIndex: 'payAmount',
  slots: {
    customRender: 'payAmount'
  },
  width: 150,
  align: 'center'
}, {
  title: '合同总金额(元)',
  dataIndex: 'totalAmount',
  slots: {
    customRender: 'totalAmount'
  },
  width: 150,
  align: 'center'
}, {
  title: '节点收支比例',
  dataIndex: 'payProportion',
  slots: {
    customRender: 'payProportion'
  },
  width: 150,
  align: 'center'
}, {
  title: '节点备注',
  dataIndex: 'remark',
  slots: {
    customRender: 'remark'
  },
  width: 250,
  align: 'center'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  },
  width: 80,
  align: 'center'
}];
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined,
    PartitionOutlined
  },
  props: {
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      projectName: undefined,
      contractName: undefined,
      changeNo: "",
      changeReason: "",
      changeAmount: undefined,
      changeProfessional: undefined,
      changeContent: "",
      changeResult: "",
      fileUrl: [],
      itemId: undefined,
      positivePlans: [],
      negativePlans: [],
      justTotalAmount: undefined,
      negativeTotalAmount: undefined,
      // approvalProcedureDtos:[], //审批流程
      approvalDtos: [],
      hyApproachApprovals: [],
      resource: 0,
      content: "",
      //审批意见
      annotation: [],
      //批注后文件
      revisePayroll: [],
      //修订后合同
      updateContractList: [],
      //修订后合同集
      reviseCon: [],
      //修改时需要修改修订后合同时用
      contractNo: null
    });
    const {
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      projectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'change'
      }],
      contractName: [{
        required: true,
        message: '合同名称不能为空',
        trigger: 'change'
      }],
      contractNo: [{
        required: true,
        message: '合同编号不能为空',
        trigger: 'change'
      }],
      changeAmount: [{
        required: true,
        validator: money,
        trigger: 'blur'
      }],
      changeNo: [{
        required: true,
        message: '变更单号不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      changeReason: [{
        required: true,
        message: '变更原因不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      changeContent: [{
        required: true,
        message: '变更内容不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      changeResult: [{
        required: true,
        message: '变更结果不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.fileUrl.length == 0
      }],
      // revisePayroll: [
      //   {
      //     required: true,
      //     validator: signFileurl,
      //     type: 'array',
      //     // trigger: 'change',
      //     trigger: formState.revisePayroll.length == 0,
      //   },
      // ],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };
    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return false;
      } else if (props.applyDetailData.state == 20) {
        return false;
      }
    });
    const isDisabledForSp = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return true;
      } else if (props.applyDetailData.state == 20) {
        return true;
      }
    }); //获取附件的url

    const getAdjuncts = val => {
      formState.fileUrl = val;
    };

    const getAdjuncts1 = val => {
      // console.log('getAdjuncts',val)
      formState.annotation = val;
    };

    const getAdjunctsForRev = val => {
      console.log('getAdjuncts', val);
      formState.revisePayroll = val;
    };

    const getAdjunctsForRevCon = val => {
      // console.log('getAdjuncts',val)
      formState.reviseCon = val;
    }; //项目名称查询


    const projectNameData = ref([]);
    const contractNameData = ref([]);
    const contractNoData = ref([]);

    const handleSearch = val => {
      fetchForMat(val, null);
    };

    const handleChange = index => {
      formState.contractName = undefined;
    }; //合同名查询


    const handleSearchForCot = val => {
      fetchForMat1(formState.projectName, val, labelName);
    };

    const fetchForMat = async (projectName, contractName) => {
      let res = await selectContractByParam({
        projectName,
        contractName,
        type: 1
      }); // console.log(res)

      try {
        projectNameData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            projectNameData.value.push({
              value: ixt.projectName,
              text: ixt.projectName
            });
          });
        }

        projectNameData.value = uniqueJsonArray(projectNameData.value, 'text');
      } catch (error) {
        console.log(error);
      }
    };

    const fetchForMat1 = async (projectName, contractName, labelName) => {
      let res = await selectContractByParam({
        projectName,
        [labelName]: contractName,
        type: 1
      }); // console.log(res)

      try {
        contractNameData.value = [];
        contractNoData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            contractNameData.value.push({
              value: ixt.contractName,
              text: ixt.contractName,
              applyId: ixt.id,
              contractNo: ixt.contractNo
            });
            contractNoData.value.push({
              value: ixt.contractNo,
              text: ixt.contractNo,
              applyId: ixt.id,
              contractName: ixt.contractName
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }; //去重


    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    };

    const onDelete = key => {
      // formState.positivePlans =formState.positivePlans.filter(item => item.key !== key);
      formState.positivePlans.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        nodeDesc: undefined,
        planPayTime: undefined,
        payTerms: undefined,
        payAmount: undefined,
        payProportion: undefined,
        contractName: undefined,
        remark: null
      };
      formState.positivePlans.push(newData);
    };

    const onDeleteForNeg = key => {
      // formState.negativePlans =formState.negativePlans.filter(item => item.key !== key);
      formState.negativePlans.splice(key, 1);
    };

    const handleAddForNeg = () => {
      const newData = {
        nodeDesc: undefined,
        planPayTime: undefined,
        payTerms: undefined,
        payAmount: undefined,
        payProportion: undefined,
        contractName: undefined,
        remark: null
      };
      formState.negativePlans.push(newData);
    };

    const findDataIsOk = (arr, i) => {
      if (i <= 0) {
        return false;
      } else {
        if (new Date(arr[i].paymentTime.startOf('day').format('YYYY-MM-DD')) > new Date(arr[i - 1].paymentTime.startOf('day').format('YYYY-MM-DD'))) {
          return findDataIsOk(arr, i - 1);
        } else {
          return true;
        }
      }
    };

    const getTotalContractAmount = val => {
      val = `￥ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return val;
    }; //审批


    const handlePassOrReject = async num => {
      if (!formState.content && formState.resource == 1) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        applyId: formState.changeApplyId,
        content: formState.content,
        state: num,
        belongProject: formState.belongProject,
        fileUrl: JSON.stringify(formState.annotation)
      };
      const res = await updateToChangeApproval(param);

      if (res.code == 200) {
        _message.success('审批成功！');

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        _message.error(res.message);
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "其他审批人";

        case 4:
          return "财务审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        let isOk = false;

        if (formState.positivePlans.length) {
          isOk = findDataIsOk(formState.positivePlans, formState.positivePlans.length - 1);
        }

        if (formState.negativePlans.length) {
          isOk = findDataIsOk(formState.negativePlans, formState.negativePlans.length - 1);
        }

        if (isOk) {
          _message.error('计划收支时间必须顺序填写');

          return;
        }

        if (!formState.negativePlans.length && !formState.positivePlans.length) {
          _message.error('正变更收支计划或负变更收支计划必须有一项有节点');

          return;
        }

        let sum = 0;

        if (formState.justTotalAmount === undefined && formState.negativeTotalAmount !== undefined) {
          sum = -formState.negativeTotalAmount;
        } else if (formState.justTotalAmount !== undefined && formState.negativeTotalAmount === undefined) {
          sum = formState.justTotalAmount;
        } else if (formState.justTotalAmount && formState.negativeTotalAmount) {
          sum = formState.justTotalAmount - formState.negativeTotalAmount;
        }

        if (sum !== formState.changeAmount) {
          _message.error('变更金额要等于正变更总金额与负变更总金额之和');

          return;
        }

        let plans = [];
        let justTotalAmount = 0;
        let negativeTotalAmount = 0;
        formState.positivePlans.forEach((item, index) => {
          justTotalAmount += item.payAmount;
          let obj = {
            nodeDesc: item.nodeDesc,
            planPayTime: item.planPayTime.startOf('day').format('YYYY-MM-DD'),
            payTerms: item.payTerms,
            payAmount: item.payAmount,
            totalAmount: formState.justTotalAmount,
            payProportion: `${(item.payAmount / formState.justTotalAmount * 100).toFixed(2)}%`,
            contractName: formState.contractName,
            remark: item.remark,
            serialNo: index + 1,
            changeType: 1
          };
          plans.push(obj);
        });
        formState.negativePlans.forEach((item, index) => {
          negativeTotalAmount += item.payAmount;
          let obj = {
            nodeDesc: item.nodeDesc,
            planPayTime: item.planPayTime.startOf('day').format('YYYY-MM-DD'),
            payTerms: item.payTerms,
            payAmount: -item.payAmount,
            totalAmount: -formState.negativeTotalAmount,
            payProportion: `${(item.payAmount / formState.negativeTotalAmount * 100).toFixed(2)}%`,
            contractName: formState.contractName,
            remark: item.remark,
            serialNo: index + 1,
            changeType: 0
          };
          plans.push(obj);
        });

        if (formState.positivePlans.length != 0 && justTotalAmount != formState.justTotalAmount) {
          _message.error('正变更收支计划中变更总金额要等于各项收支金额之和');

          return;
        }

        if (formState.negativePlans.length != 0 && negativeTotalAmount != formState.negativeTotalAmount) {
          _message.error('负变更收支计划中变更总金额要等于各项收支金额之和');

          return;
        } // const teamMemberArr = getObj(formState.teamMember)


        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.revisePayroll = JSON.stringify(obj.reviseCon);
        obj.plans = plans;
        obj.changeProfessional = obj.changeProfessional ? obj.changeProfessional.toString() : null; // console.log(obj)

        loading.value = true;
        let res = await updateChangeApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //修订


    const handleRevise = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        let isOk = false;

        if (formState.positivePlans.length) {
          isOk = findDataIsOk(formState.positivePlans, formState.positivePlans.length - 1);
        }

        if (formState.negativePlans.length) {
          isOk = findDataIsOk(formState.negativePlans, formState.negativePlans.length - 1);
        }

        if (isOk) {
          _message.error('计划收支时间必须顺序填写');

          return;
        }

        if (!formState.negativePlans.length && !formState.positivePlans.length) {
          _message.error('正变更收支计划或负变更收支计划必须有一项有节点');

          return;
        }

        let sum = 0;

        if (formState.justTotalAmount === undefined && formState.negativeTotalAmount !== undefined) {
          sum = -formState.negativeTotalAmount;
        } else if (formState.justTotalAmount !== undefined && formState.negativeTotalAmount === undefined) {
          sum = formState.justTotalAmount;
        } else if (formState.justTotalAmount && formState.negativeTotalAmount) {
          sum = formState.justTotalAmount - formState.negativeTotalAmount;
        }

        if (sum !== formState.changeAmount) {
          _message.error('变更金额要等于正变更总金额与负变更总金额之和');

          return;
        }

        let plans = [];
        let justTotalAmount = 0;
        let negativeTotalAmount = 0;
        formState.positivePlans.forEach((item, index) => {
          justTotalAmount += item.payAmount;
          let obj = {
            nodeDesc: item.nodeDesc,
            planPayTime: item.planPayTime.startOf('day').format('YYYY-MM-DD'),
            payTerms: item.payTerms,
            payAmount: item.payAmount,
            totalAmount: formState.justTotalAmount,
            payProportion: `${(item.payAmount / formState.justTotalAmount * 100).toFixed(2)}%`,
            contractName: formState.contractName,
            serialNo: index + 1,
            remark: item.remark,
            changeType: 1
          };
          plans.push(obj);
        });
        formState.negativePlans.forEach((item, index) => {
          negativeTotalAmount += item.payAmount;
          let obj = {
            nodeDesc: item.nodeDesc,
            planPayTime: item.planPayTime.startOf('day').format('YYYY-MM-DD'),
            payTerms: item.payTerms,
            payAmount: -item.payAmount,
            totalAmount: -formState.negativeTotalAmount,
            payProportion: `${(item.payAmount / formState.negativeTotalAmount * 100).toFixed(2)}%`,
            contractName: formState.contractName,
            serialNo: index + 1,
            remark: item.remark,
            changeType: 0
          };
          plans.push(obj);
        });

        if (formState.positivePlans.length != 0 && justTotalAmount != formState.justTotalAmount) {
          _message.error('正变更收支计划中变更总金额要等于各项收支金额之和');

          return;
        }

        if (formState.negativePlans.length != 0 && negativeTotalAmount != formState.negativeTotalAmount) {
          _message.error('负变更收支计划中变更总金额要等于各项收支金额之和');

          return;
        } // const teamMemberArr = getObj(formState.teamMember)


        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.revisePayroll = JSON.stringify(obj.revisePayroll);
        obj.plans = plans;
        obj.changeProfessional = obj.changeProfessional ? obj.changeProfessional.toString() : null; // console.log(obj)

        loading.value = true;
        let res = await changeUpdateReviseApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const getData = async key => {
      const res = await changeSelectApplyById(key);

      try {
        Object.assign(formState, res.data);
        formState.fileUrl = JSON.parse(formState.fileUrl);
        formState.changeProfessional = formState.changeProfessional ? formState.changeProfessional.split(',') : undefined;
        formState.positivePlans.forEach(item => {
          formState.justTotalAmount = item.totalAmount;
          item.key = item.serialNo;
          item.planPayTime = moment(item.planPayTime, 'YYYY-MM-DD');
        });
        formState.negativePlans.forEach(item => {
          formState.negativeTotalAmount = Math.abs(item.totalAmount);
          item.payAmount = Math.abs(item.payAmount);
          item.key = item.serialNo;
          item.planPayTime = moment(item.planPayTime, 'YYYY-MM-DD');
        });
        formState.reviseCon = formState.updateContractList.length ? JSON.parse(formState.updateContractList[formState.updateContractList.length - 1].fileUrl) : [];
        formState.revisePayroll = formState.revisePayroll == null ? [] : formState.revisePayroll;
        console.log(formState);
      } catch (error) {
        console.log('合同详情:', error);
      }
    }; //合同是否可编辑


    const disabledForContract = computed(() => {
      if (formState.projectName && !isDisabledForCl) {
        if (isCotModify) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }); //合同编号是否可编辑

    const disabledForContractNo = computed(() => {
      if (formState.projectName && !isDisabledForCl) {
        if (isModifyForCot) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }); //修改模式是否显示最后一次修订合同是否可修改

    const isModifyForCot = index => {
      if (formState.updateContractList.length && props.applyDetailData.state == 2 && index == formState.updateContractList.length - 1) {
        return false;
      } else {
        return true;
      }
    }; //修改模式是否显示变更单是否显示可修改


    const isShowFileUrlByUp = computed(() => {
      if (props.applyDetailData.state == 2) {
        if (formState.updateContractList.length) {
          return false;
        } else {
          return true;
        }
      } else if (props.applyDetailData.state !== 2) {
        return false;
      }
    });
    const isShowFileUrl = computed(() => {
      if (props.applyDetailData.state == 2) {
        if (formState.updateContractList.length) {
          return true;
        } else {
          return false;
        }
      } else if (props.applyDetailData.state !== 2) {
        return true;
      }
    }); //所属项目查询

    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 5,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const isCotModify = ref(false);
    const isCotNoModify = ref(false);

    const handleChangeForCotNo = val => {
      // console.log(val)
      if (val) {
        isCotModify.value = true;
        contractNoData.value.find(item => item.value === val).contractName;
      } else {
        isCotModify.value = false;
        formState.contractName = null;
      }
    };

    const handleChangeForCot = val => {
      // console.log(val)
      if (val) {
        isCotNoModify.value = true; // contractNoData.value.find(item => item.value === val).changeNo
      } else {
        isCotNoModify.value = false;
        formState.changeNo = null;
      }
    }; //系统生成


    const handleGenerate = async () => {
      let res = await sysgenChangeNo({
        contractNo: formState.contractNo
      });

      if (res.code == 200) {
        formState.changeNo = res.data;
      } else {
        _message.error(res.message);
      }
    }; //所属专业


    const professionalOptions = ref([]);

    const getProfessionalByItem = async () => {
      let res = await selectProfessionalByItem({
        itemId: formState.itemId
      });

      if (res.code === 200) {
        professionalOptions.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            professionalOptions.value.push({
              value: item.professionalName,
              label: item.professionalName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const lineIsShowFileUrl = val => {
      let arr = JSON.parse(val);
      console.log(arr);

      if (arr && arr.length) {
        return true;
      } else {
        return false;
      }
    };

    onMounted(() => {
      getApprovalItem();
      getData(props.applyDetailData.key);
    });
    return {
      closeApplyVisible,
      formState,
      formRef,
      rules,
      projectNameData,
      contractNameData,
      handleSearch,
      handleChange,
      handleSearchForCot,
      getAdjuncts,
      columns,
      onDelete,
      handleAdd,
      onDeleteForNeg,
      handleAddForNeg,
      getTotalContractAmount,
      money,
      handleOk,
      getAdjunctsForRev,
      isDisabledForCl,
      isDisabledForSp,
      getAdjuncts1,
      handleRevise,
      handlePassOrReject,
      getCurrent,
      setRoleType,
      setStatus,
      disabledForContract,
      isModifyForCot,
      isShowFileUrlByUp,
      isShowFileUrl,
      getAdjunctsForRevCon,
      projectOptions,
      disabledForContractNo,
      isCotModify,
      handleChangeForCot,
      isCotNoModify,
      handleGenerate,
      professionalOptions,
      getProfessionalByItem,
      handleChangeForCotNo,
      lineIsShowFileUrl
    };
  }

});