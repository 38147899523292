import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { insertChangeApply, selectContractByParam, sysgenChangeNo } from '@/api/contract';
import { selectApprovalItem, selectProfessionalByItem } from '@/api/material';
import Cookies from "js-cookie";
const columns = [// {
//   title: '序号',
//   dataIndex: 'id',
//   slots: {
//     customRender: 'id',
//   },
// },
{
  title: '收支节点',
  // className: 'column-money',
  dataIndex: 'payNode',
  fixed: 'left',
  children: [{
    title: '节点描述',
    dataIndex: 'nodeDescriptor',
    // key: 'companyAddress',
    slots: {
      customRender: 'nodeDescriptor'
    },
    width: 300,
    align: 'center'
  }, {
    title: '计划收支时间',
    dataIndex: 'paymentTime',
    // key: 'paymentTime',
    slots: {
      customRender: 'paymentTime'
    },
    width: 200,
    align: 'center'
  }]
}, {
  title: '收支条件',
  dataIndex: 'paymentTerms',
  slots: {
    customRender: 'paymentTerms'
  },
  width: 250,
  align: 'center'
}, {
  title: '收支金额(元)',
  dataIndex: 'paymentAmount',
  slots: {
    customRender: 'paymentAmount'
  },
  width: 150,
  align: 'center'
}, {
  title: '变更总金额(元)',
  dataIndex: 'totalContractAmount',
  slots: {
    customRender: 'totalContractAmount'
  },
  width: 150,
  align: 'center'
}, {
  title: '节点收支比例',
  dataIndex: 'paymentRatio',
  slots: {
    customRender: 'paymentRatio'
  },
  width: 150,
  align: 'center'
}, {
  title: '节点备注',
  dataIndex: 'remark',
  slots: {
    customRender: 'remark'
  },
  width: 250,
  align: 'center'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  },
  align: 'center',
  width: 80
}];
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      projectName: undefined,
      contractName: undefined,
      changeNo: "",
      changeReason: "",
      changeAmount: undefined,
      changeProfessional: undefined,
      changeContent: "",
      changeResult: "",
      fileUrl: [],
      paymentRecords: [],
      negPaymentRecords: [],
      justTotalAmount: undefined,
      negativeTotalAmount: undefined,
      itemId: undefined,
      contractNo: null
    });
    const {
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      projectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'change'
      }],
      contractName: [{
        required: true,
        message: '合同名称不能为空',
        trigger: 'change'
      }],
      contractNo: [{
        required: true,
        message: '合同编号不能为空',
        trigger: 'change'
      }],
      changeAmount: [{
        required: true,
        message: '变更金额不能为空',
        trigger: 'blur',
        type: 'number'
      }],
      changeNo: [{
        required: true,
        message: '变更单号不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      changeReason: [{
        required: true,
        message: '变更原因不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      changeContent: [{
        required: true,
        message: '变更内容不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      changeResult: [{
        required: true,
        message: '变更结果不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.fileUrl.length == 0
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //获取附件的url

    const getAdjuncts = val => {
      formState.fileUrl = val;
    }; //项目名称查询


    const projectNameData = ref([]);
    const contractNameData = ref([]);
    const contractNoData = ref([]);

    const handleSearch = val => {
      fetchForMat(val, null);
    };

    const handleChange = index => {
      formState.contractName = undefined;
    }; //合同名/编号查询


    const handleSearchForCot = (val, labelName) => {
      fetchForMat1(formState.projectName, val, labelName);
    };

    const fetchForMat = async (projectName, contractName) => {
      let res = await selectContractByParam({
        projectName,
        contractName,
        type: 1
      }); // console.log(res)

      try {
        projectNameData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            projectNameData.value.push({
              value: ixt.projectName,
              text: ixt.projectName
            });
          });
        }

        projectNameData.value = uniqueJsonArray(projectNameData.value, 'text');
      } catch (error) {
        console.log(error);
      }
    };

    const fetchForMat1 = async (projectName, contractName, labelName) => {
      let res = await selectContractByParam({
        projectName,
        [labelName]: contractName,
        type: 1
      }); // console.log(res)

      try {
        contractNameData.value = [];
        contractNoData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            contractNameData.value.push({
              value: ixt.contractName,
              text: ixt.contractName,
              applyId: ixt.id,
              contractNo: ixt.contractNo
            });
            contractNoData.value.push({
              value: ixt.contractNo,
              text: ixt.contractNo,
              applyId: ixt.id,
              contractName: ixt.contractName
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }; //去重


    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    };

    const onDelete = key => {
      // formState.paymentRecords =formState.paymentRecords.filter(item => item.key !== key);
      formState.paymentRecords.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        nodeDescriptor: undefined,
        paymentTime: undefined,
        paymentTime: undefined,
        paymentAmount: undefined,
        paymentRatio: undefined,
        remark: null
      };
      formState.paymentRecords.push(newData);
    };

    const onDeleteForNeg = key => {
      // formState.paymentRecords =formState.paymentRecords.filter(item => item.key !== key);
      formState.negPaymentRecords.splice(key, 1);
    };

    const handleAddForNeg = () => {
      const newData = {
        nodeDescriptor: undefined,
        paymentTime: undefined,
        paymentTime: undefined,
        paymentAmount: undefined,
        paymentRatio: undefined,
        remark: null
      };
      formState.negPaymentRecords.push(newData);
    };

    const findDataIsOk = (arr, i) => {
      if (i <= 0) {
        return false;
      } else {
        if (new Date(arr[i].paymentTime.startOf('day').format('YYYY-MM-DD')) > new Date(arr[i - 1].paymentTime.startOf('day').format('YYYY-MM-DD'))) {
          return findDataIsOk(arr, i - 1);
        } else {
          return true;
        }
      }
    };

    const getTotalContractAmount = val => {
      val = `￥ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return val;
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      console.log(formState);
      formRef.value.validate().then(async () => {
        let isOk = false;

        if (formState.paymentRecords.length) {
          isOk = findDataIsOk(formState.paymentRecords, formState.paymentRecords.length - 1);
        }

        if (formState.negPaymentRecords.length) {
          isOk = findDataIsOk(formState.negPaymentRecords, formState.negPaymentRecords.length - 1);
        }

        if (isOk) {
          _message.error('计划收支时间必须顺序填写');

          return;
        }

        if (!formState.negPaymentRecords.length && !formState.paymentRecords.length) {
          _message.error('正变更收支计划或负变更收支计划必须有一项有节点');

          return;
        }

        let sum = 0;

        if (formState.justTotalAmount === undefined && formState.negativeTotalAmount !== undefined) {
          sum = -formState.negativeTotalAmount;
        } else if (formState.justTotalAmount !== undefined && formState.negativeTotalAmount === undefined) {
          sum = formState.justTotalAmount;
        } else if (formState.justTotalAmount && formState.negativeTotalAmount) {
          sum = formState.justTotalAmount - formState.negativeTotalAmount;
        }

        if (sum !== formState.changeAmount) {
          _message.error('变更金额要等于正变更总金额与负变更总金额之和');

          return;
        }

        let plans = [];
        let justTotalAmount = 0;
        let negativeTotalAmount = 0;
        formState.paymentRecords.forEach((item, index) => {
          justTotalAmount += item.paymentAmount;
          let obj = {
            nodeDesc: item.nodeDescriptor,
            planPayTime: item.paymentTime.startOf('day').format('YYYY-MM-DD'),
            payTerms: item.paymentTerms,
            payAmount: item.paymentAmount,
            totalAmount: formState.justTotalAmount,
            payProportion: `${(item.paymentAmount / formState.justTotalAmount * 100).toFixed(2)}%`,
            contractName: formState.contractName,
            remark: item.remark,
            serialNo: index + 1,
            changeType: 1
          };
          plans.push(obj);
        });
        formState.negPaymentRecords.forEach((item, index) => {
          negativeTotalAmount += item.paymentAmount;
          let obj = {
            nodeDesc: item.nodeDescriptor,
            planPayTime: item.paymentTime.startOf('day').format('YYYY-MM-DD'),
            payTerms: item.paymentTerms,
            payAmount: -item.paymentAmount,
            totalAmount: -formState.negativeTotalAmount,
            payProportion: `${(item.paymentAmount / formState.negativeTotalAmount * 100).toFixed(2)}%`,
            contractName: formState.contractName,
            remark: item.remark,
            serialNo: index + 1,
            changeType: 0
          };
          plans.push(obj);
        });

        if (formState.paymentRecords.length != 0 && justTotalAmount != formState.justTotalAmount) {
          _message.error('正变更收支计划中变更总金额要等于各项收支金额之和');

          return;
        }

        if (formState.negPaymentRecords.length != 0 && negativeTotalAmount != formState.negativeTotalAmount) {
          _message.error('负变更收支计划中变更总金额要等于各项收支金额之和');

          return;
        } // console.log(justTotalAmount,negativeTotalAmount)
        // const teamMemberArr = getObj(formState.teamMember)


        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.plans = plans;
        obj.changeProfessional = obj.changeProfessional ? obj.changeProfessional.toString() : null; // console.log(obj)

        loading.value = true;
        let res = await insertChangeApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 5,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const isCotModify = ref(false);
    const isCotNoModify = ref(false);

    const handleChangeForCotNo = val => {
      // console.log(val)
      if (val) {
        isCotModify.value = true;
        formState.contractName = contractNoData.value.find(item => item.value === val).contractName;
        formRef.value.clearValidate(['contractName']);
      } else {
        isCotModify.value = false;
        formState.contractName = null;
      }
    };

    const handleChangeForCot = val => {
      // console.log(val)
      if (val) {
        isCotNoModify.value = true;
        formState.contractNo = contractNameData.value.find(item => item.value === val).contractNo;
        formRef.value.clearValidate(['contractNo']);
      } else {
        isCotNoModify.value = false;
        formState.contractNo = null;
      }
    }; //系统生成


    const handleGenerate = async () => {
      let res = await sysgenChangeNo({
        contractNo: formState.contractNo
      });

      if (res.code == 200) {
        formState.changeNo = res.data;
        formRef.value.clearValidate(['changeNo']);
      } else {
        _message.error(res.message);
      }
    }; //所属专业


    const professionalOptions = ref([]);

    const getProfessionalByItem = async () => {
      let res = await selectProfessionalByItem({
        itemId: formState.itemId
      });

      if (res.code === 200) {
        professionalOptions.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            professionalOptions.value.push({
              value: item.professionalName,
              label: item.professionalName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formState,
      formRef,
      rules,
      projectNameData,
      contractNameData,
      handleSearch,
      handleChange,
      handleSearchForCot,
      getAdjuncts,
      columns,
      onDelete,
      handleAdd,
      onDeleteForNeg,
      handleAddForNeg,
      getTotalContractAmount,
      money,
      handleOk,
      loading,
      projectOptions,
      contractNoData,
      handleChangeForCotNo,
      isCotModify,
      handleChangeForCot,
      isCotNoModify,
      handleGenerate,
      professionalOptions,
      getProfessionalByItem
    };
  }

});